import { useState, useEffect } from "react";
import ArrowTopImg from "../../assets/img/arrowTop.svg";
import classNames from "classnames/dedupe";
import styles from "./ArrowTop.module.sass";

export const ArrowTop = () => {
  const [showButton, setShowButton] = useState(false);
  const handleScroll = () => {
    if (window.scrollY >= 600) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      className={classNames(styles.button, showButton ? styles.visible : "")}
      onClick={scrollToTop}
    >
      <img src={ArrowTopImg} alt="Top" />
    </button>
  );
};
