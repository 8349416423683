import { FC, useCallback, useEffect } from "react";

interface IUseBodyClass {
  className: string;
}
export const useBodyClass = ({ className }: IUseBodyClass) => {
  const body = document.getElementById("body");

  const lockBody = useCallback(() => {
    body?.classList.add(className);
  }, []);

  const unlockBody = useCallback(() => {
    body?.classList.remove(className);
  }, []);

  return { lockBody, unlockBody };
};
