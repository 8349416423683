import iconDone from "../../../../assets/img/formIcon-done.svg";
import styles from "./Form.module.sass";
import classNames from "classnames/dedupe";
import { FC } from "react";

interface IFinishFormProps {
  close: () => void;
}

export const FinishForm: FC<IFinishFormProps> = ({ close }) => {
  return (
    <div className={classNames("df-between", "fd-column", styles.finishForm)}>
      <img className={styles.iconDone} src={iconDone} alt="icon: Done" />
      <p className={styles.formText}>
        Дякуємо Вам за реєстрацію та зацікавленість заходом.
      </p>
      <p className={styles.formText}>
        Чекайте на зворотний зв'язок. Додатковий інформаційний гайд буде ближче
        до дня події.
      </p>
      <p className={styles.formText}>
        До зустрічі на форумі PRO СИНТЕЗ бізнес!
      </p>
      <button className={styles.btn} onClick={close}>
        закрити
      </button>
    </div>
  );
};
