import { Button } from "../../../../components/Button";
import styles from "./Tickets.module.sass";
import { Container } from "../../../../components/Container";
import BannerImg from "../../../../assets/img/Banner3.jpg";
import classNames from "classnames/dedupe";
import { useDialog } from "../../../../hooks/useDialog";

export const Tickets = () => {
  const { showDialog } = useDialog();

  const ticketPrices = {
    BeforeOctober31: {
      Adult: 1250,
      Child: 1500,
      Senior: 2000,
    },
    AfterOctober31: {
      Adult: 1500,
      Child: 1800,
      Senior: 2500,
    },
  };

  const getTicketPrice = (ticketType: "Adult" | "Child" | "Senior"): number => {
    const november1 = new Date("2023-11-01");

    const dateType =
      new Date() < november1 ? "BeforeOctober31" : "AfterOctober31";

    return ticketPrices[dateType][ticketType];
  };

  const isCrossedPrice = (): boolean => {
    const november1 = new Date("2023-11-01");
    return new Date() < november1;
  };

  return (
    <div className={styles.root} id="tickets">
      <div className={styles.bannerWrapper}>
        <img className="img" src={BannerImg} alt="img: Banner" />
      </div>
      <Container className={classNames("df-between", styles.container)}>
        <p className={styles.title}>ВАРІАНТИ УЧАСТІ</p>
        <div className={styles.wrapper}>
          <div className={styles.goldTicket}>
            <div
              className={classNames(
                styles.ticketHeader,
                styles.goldTicketHeader,
              )}
            >
              <h1
                className={classNames(
                  styles.ticketTitle,
                  styles.goldTicketTitle,
                )}
              >
                workshop
              </h1>
            </div>
            <div
              className={classNames(
                "df-between fd-column",
                styles.ticketBody,
                styles.goldTicketBody,
              )}
            >
              <div
                className={classNames(
                  styles.bodyList,
                  styles.goldTicketBodyList,
                )}
              >
                <ul
                  className={classNames(
                    styles.ticketList,
                    styles.goldTicketList,
                  )}
                >
                  <li>Участь у Workshop</li>
                  <li>Кава-брейки</li>
                  <li>Нетворкінг з учасниками</li>
                  <li>Фотозвіт із події</li>
                </ul>
              </div>
              <div>
                <p className={styles.addText}>
                  100% виручених коштів з воркшопів ми віддамо нашим повітряним
                  оберегам — Полтавському полку зенітно-ракетних військ
                </p>
              </div>
              <div className={styles.btn}>
                <Button
                  name={"Обрати workshop"}
                  onClick={() => showDialog({ open: true })}
                  link={""}
                />
              </div>
            </div>
          </div>

          <div className={classNames(styles.ticket)}>
            <div className={styles.ticketHeader}>
              <h1 className={styles.ticketTitle}>Standard</h1>
            </div>
            <div
              className={classNames("df-between fd-column", styles.ticketBody)}
            >
              <div className={styles.bodyList}>
                <ul className={styles.ticketList}>
                  <li>Участь у форумі</li>
                  <li>Кава-брейки</li>
                  <li>Відеозапис воркшопів</li>
                  <li>Участь у розіграшах</li>
                  <li>Нетворкінг з учасниками</li>
                  <li className={styles.itemGold}>
                    <p>Workshop</p>
                  </li>
                  <li>Фотозвіт із події</li>
                  <li>Можливість обрати місце в секторі Standard</li>
                </ul>
              </div>
              <div>
                <p className={styles.addText}>
                  *25% виручених коштів з продажу квитків ми передаємо на
                  допомогу Полтавському зенітно-ракетному полку
                </p>
                {isCrossedPrice() ? (
                  <p className={styles.crosedPrice}>
                    1500<sup>грн</sup>
                  </p>
                ) : (
                  ""
                )}
                <strong className={styles.price}>
                  {getTicketPrice("Adult")}
                  <sup>грн</sup>
                </strong>
              </div>
              <div className={styles.btn}>
                <Button />
              </div>
            </div>
          </div>

          <div className={styles.ticket}>
            <div className={styles.ticketHeader}>
              <h1 className={styles.ticketTitle}>Standard</h1>
              <p className={styles.ticketSubtitle}>(Relax)</p>
            </div>
            <div
              className={classNames("df-between fd-column", styles.ticketBody)}
            >
              <div className={styles.bodyList}>
                <ul className={styles.ticketList}>
                  <li>Участь у форумі</li>
                  <li>Кава-брейки</li>
                  <li>Відеозапис воркшопів</li>
                  <li>Участь у розіграшах</li>
                  <li>Нетворкінг з учасниками</li>
                  <li className={styles.itemGold}>
                    <p>Workshop</p>
                  </li>
                  <li>Фотозвіт із події</li>
                </ul>
                <ul className={styles.ticketListPlus}>
                  <li>
                    Можливість обрати місце на зручному диванчику в секторі
                    Standard (Relax)
                  </li>
                </ul>
              </div>
              <div>
                <p className={styles.addText}>
                  *25% виручених коштів з продажу квитків ми передаємо на
                  допомогу Полтавському зенітно-ракетному полку
                </p>
                {isCrossedPrice() ? (
                  <p className={styles.crosedPrice}>
                    1800<sup>грн</sup>
                  </p>
                ) : (
                  ""
                )}
                <strong className={styles.price}>
                  {getTicketPrice("Child")}
                  <sup>грн</sup>
                </strong>
              </div>
              <div className={styles.btn}>
                <Button />
              </div>
            </div>
          </div>

          <div className={styles.ticket}>
            <div className={styles.ticketHeader}>
              <h1 className={styles.ticketTitle}>VIP</h1>
            </div>
            <div
              className={classNames("df-between fd-column", styles.ticketBody)}
            >
              <div className={styles.bodyList}>
                <ul className={styles.ticketList}>
                  <li>Участь у форумі</li>
                  <li>Кава-брейки</li>
                  <li>Пакет учасника</li>
                  <li>Участь у розіграшах</li>
                  <li>Нетворкінг з учасниками</li>
                  <li className={styles.itemGold}>
                    <p>Workshop</p>
                  </li>
                  <li>Фотозвіт із події</li>
                </ul>
                <ul className={styles.ticketListPlus}>
                  <li>
                    Можливість обрати місце в секторі VIP (в перших рядах)
                  </li>
                  <li>
                    Вечеря та витонченний нетворкінг зі спікерами після
                    завершення форуму
                  </li>
                </ul>
              </div>
              <div>
                <p className={styles.addText}>
                  *25% виручених коштів з продажу квитків ми передаємо на
                  допомогу Полтавському зенітно-ракетному полку
                </p>
                {isCrossedPrice() ? (
                  <p className={styles.crosedPrice}>
                    2500<sup>грн</sup>
                  </p>
                ) : (
                  ""
                )}
                <strong className={styles.price}>
                  {getTicketPrice("Senior")}
                  <sup>грн</sup>
                </strong>
              </div>
              <div className={styles.btn}>
                <Button />
              </div>
            </div>
          </div>
        </div>
        <p className={styles.subText}>
          *25% виручених коштів з продажу квитків ми передаємо на допомогу
          Полтавському зенітно-ракетному полку
        </p>
      </Container>
    </div>
  );
};
