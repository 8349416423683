import { Container } from "../Container";
import classNames from "classnames/dedupe";
import { Logo, LogoSize } from "../Logo";
import TiBoxLogo from "../../assets/img/TiBox.jpg";
import FacebookIcon from "../../assets/img/facebook.svg";
import TelegramIcon from "../../assets/img/telegram.svg";
import InstagramIcon from "../../assets/img/inst.svg";
import styles from "./Footer.module.sass";
import { ROUTES } from "../../routing/constants";
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  TELEGRAM_LINK,
  TIBOX_LINK,
} from "../../constants/links";

export const Footer = () => {
  return (
    <div className={styles.root}>
      <Container
        className={classNames("df-between fd-column", styles.container)}
      >
        <div className={classNames("df-between", styles.main)}>
          <div className={styles.item}>
            <Logo size={LogoSize.medium} />
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Навігація</p>
            <ul className={styles.list}>
              <li>
                <a href={`${ROUTES.main.createURL()}#forumInfo`}>Про форум</a>
              </li>
              <li>
                <a href={`${ROUTES.main.createURL()}#tickets`}>Квитки</a>
              </li>
              <li>
                <a href={`${ROUTES.main.createURL()}#partners`}>Партнери</a>
              </li>
              <li>
                <a href={`${ROUTES.main.createURL()}#pabInfo`}>Про ПАБ</a>
              </li>
              <li>
                <a href={ROUTES.info.createURL()}>Для ЗМІ</a>
              </li>
            </ul>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Контакти</p>
            <ul className={styles.list}>
              <li>
                <a href="tel:+38 (066) 812 71 53">+38 (066) 812 71 53</a>
              </li>
              <li>
                <a href="mailto:pab.poltava@gmail.com">pab.poltava@gmail.com</a>
              </li>
            </ul>
            <a
              href={TIBOX_LINK}
              target="_blank"
              className={classNames(styles.mainIcon, styles.mainIconContact)}
            >
              <img
                className={styles.mainIcon}
                src={TiBoxLogo}
                alt="icon: TiBox"
              />
            </a>
          </div>
          <div
            className={classNames(
              "df-between fd-column",
              styles.iconsBlock,
              styles.item,
            )}
          >
            <a
              href={TIBOX_LINK}
              target="_blank"
              className={classNames(styles.mainIcon, styles.mainIconMain)}
            >
              <img
                className={styles.mainIcon}
                src={TiBoxLogo}
                alt="icon: TiBox"
              />
            </a>
            <ul className={classNames("df-between", styles.links)}>
              <li>
                <a href={FACEBOOK_LINK} target="_blank">
                  <img
                    className={styles.icon}
                    src={FacebookIcon}
                    alt="facebook"
                  />
                </a>
              </li>
              <li>
                <a href={TELEGRAM_LINK} target="_blank">
                  <img
                    className={styles.icon}
                    src={TelegramIcon}
                    alt="telegram"
                  />
                </a>
              </li>
              <li>
                <a href={INSTAGRAM_LINK} target="_blank">
                  <img
                    className={styles.icon}
                    src={InstagramIcon}
                    alt="instagram"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className={styles.footerText}>
          © Copyright 2023 — PRO Синтез Бізнес.
        </p>
      </Container>
    </div>
  );
};
