import { FC } from "react";
import styles from "./BusinessSchool.module.scss";
import Img from "../../../../assets/img/bussinessScholl.jpg";
import classNames from "classnames/dedupe";

export const BusinessSchool: FC = () => {
  return (
    <div className={styles.root}>
      <div className={classNames("df-between", styles.wrapper)}>
        <img className={styles.img} src={Img} alt={"img: Business School"} />
        <div className={styles.content}>
          <h6 className={styles.title}>
            Бізнес-школа МІМ (Міжнародний інститут менеджменту) очолює рейтинг
            українських бізнес-шкіл і входить до 5% кращих бізнес-шкіл світу за
            рейтингом Eduniversal.
          </h6>

          <p className={styles.text}>
            МІМ надає системну бізнес-освіту для власників підприємств,
            топ-менеджерів, керівників структурних підрозділів компаній, яка
            відповідає найвищим міжнародним стандартам і визнана світовою
            асоціацією МВА.
          </p>
          <p className={styles.text}>
            Бізнес-школа об’єднує 7000 випускників в спільноту тих, хто завжди
            прагне більшого, і бачить свою місію у розвитку відповідальних
            лідерів впливу на глобальну конкурентоспроможність України.
          </p>
        </div>
      </div>
    </div>
  );
};
