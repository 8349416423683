import { Container } from "../../../../components/Container";
import styles from "./InfoPanel.module.sass";
import { Button, ButtonType } from "../../../../components/Button";
import classNames from "classnames/dedupe";
import { useEffect, useState } from "react";

export const InfoPanel = () => {
  const arrSubtitle = [
    "обміну досвідом",
    "пошуку ідей",
    "спілкування з однодумцями",
    "створення партнерства",
    "змістовного дозвілля",
  ];
  const [subtitle, setSubtitle] = useState(arrSubtitle[0]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSubtitle(arrSubtitle[count]);
      setCount(count + 1);
    }, 1500);

    if (count > 4) {
      setCount(0);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [count]);

  return (
    <Container className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          <div className={styles.item}>
            <p className={styles.title}>
              PRO СИНТЕЗ БІЗНЕС це потужне місце для
            </p>
            <div className={styles.tagWrapper}>
              <p className={classNames("df-center", styles.tag)}>{subtitle}</p>
            </div>
          </div>
          <div className={styles.item}>
            <p className={styles.textStrong}>
              Бізнес – це взаємодія, синтез окремих елементів. Його успіх
              залежить від вдалого поєднання.
            </p>
          </div>
          <div className={styles.item}>
            <p className={styles.text}>
              Підприємець щодня тестує, шукає, оптимізує. І тих, хто стоїть на
              місці, зносить назад. Розвиток – це постійний рух уперед.
            </p>
          </div>
          <div className={styles.item}>
            <p className={styles.text}>
              Бізнес — про вміння дивитися на давно відоме по-новому. Це пошук
              змінних елементів, щоб примножувати й масштабуватися.
            </p>
          </div>
          <div className={styles.item}>
            <p className={styles.text}>
              Полтавська асоціація бізнесу створює можливості для пошуку власної
              формули розвитку.
            </p>
            <p className={styles.text}>Зробіть свій крок до розвитку!</p>
          </div>
          <div className={styles.item}>
            <p className={styles.textBold}>
              Приходьте на форум, створений бізнесом для бізнесу
            </p>
            <Button size={ButtonType.Medium} />
          </div>
        </div>
      </div>
    </Container>
  );
};
