import { Container } from "../Container";
import { Logo } from "../Logo";
import { Button, ButtonType } from "../Button";
import { useCallback, useEffect, useState } from "react";
import MenuIcon from "../../assets/img/menu.svg";
import classNames from "classnames/dedupe";
import styles from "./Header.module.sass";
import { useMenu } from "../../hooks/useMenu";
import { ROUTES } from "../../routing/constants";
import { useLocation, useNavigate } from "react-router-dom";

const HeaderNavigation = [
  { name: "Про форум", link: `${ROUTES.main.createURL()}#forumInfo` },
  { name: "Квитки", link: `${ROUTES.main.createURL()}#tickets` },
  { name: "Партнери", link: `${ROUTES.main.createURL()}#partners` },
  { name: "Про ПАБ", link: `${ROUTES.main.createURL()}#pabInfo` },
];

const locationPath = "/info";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showBg, setShowBg] = useState(false);
  const { showMenu } = useMenu();

  const handleScroll = useCallback(() => {
    if (location.pathname !== locationPath) {
      if (window.scrollY >= window.outerHeight - 10) {
        setShowBg(true);
      } else {
        setShowBg(false);
      }
    }
  }, []);

  useEffect(() => {
    if (location.pathname === locationPath) {
      setShowBg(true);
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleShowMenu = useCallback(() => {
    showMenu({ open: true });
  }, []);

  return (
    <>
      <header className={classNames(styles.header, showBg ? styles.bg : "")}>
        <Container className={classNames("df-between", styles.container)}>
          <a href={ROUTES.main.createURL()}>
            <Logo />
          </a>
          <nav>
            <ul className={classNames("df-between", styles.list)}>
              {HeaderNavigation.map((item) => (
                <li className={styles.listItems} key={item.link + item.name}>
                  <a href={item.link}>{item.name}</a>
                </li>
              ))}
            </ul>
          </nav>
          <Button size={ButtonType.Small}></Button>
          <button
            className={classNames("df-center", styles.menu)}
            onClick={handleShowMenu}
          >
            <img src={MenuIcon} alt="Menu icon" />
          </button>
        </Container>
      </header>
    </>
  );
};
