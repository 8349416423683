import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "../../../../components/Container";
import { Mousewheel, Pagination } from "swiper";
import styles from "./SliderCarusel.module.sass";
import classNames from "classnames/dedupe";
import "swiper/css";
import "swiper/css/pagination";
import Img1 from "../../../../assets/img/photos/1.jpg";
import Img2 from "../../../../assets/img/photos/2.jpg";
import Img3 from "../../../../assets/img/photos/3.jpg";
import Img4 from "../../../../assets/img/photos/4.jpg";
import Img5 from "../../../../assets/img/photos/5.jpg";
import Img6 from "../../../../assets/img/photos/6.jpg";
import Img7 from "../../../../assets/img/photos/7.jpg";
import Img8 from "../../../../assets/img/photos/8.jpg";
import Img9 from "../../../../assets/img/photos/9.jpg";
import Img10 from "../../../../assets/img/photos/10.jpg";
import Img11 from "../../../../assets/img/photos/11.jpg";
import Img12 from "../../../../assets/img/photos/12.jpg";
import Img13 from "../../../../assets/img/photos/13.jpg";
import Img14 from "../../../../assets/img/photos/14.jpg";
import Img15 from "../../../../assets/img/photos/15.jpg";
import Img16 from "../../../../assets/img/photos/16.jpg";
import Img17 from "../../../../assets/img/photos/17.jpg";
import Img18 from "../../../../assets/img/photos/18.jpg";
import Img19 from "../../../../assets/img/photos/19.jpg";
import Img20 from "../../../../assets/img/photos/20.jpg";

export const SliderCarusel = () => {
  const imgs = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,
    Img16,
    Img17,
    Img18,
    Img19,
    Img20,
  ];
  return (
    <Container className={styles.container}>
      <Swiper
        className={styles.swiper}
        loop={true}
        slidesPerView={1}
        spaceBetween={30}
        modules={[Pagination, Mousewheel]}
        pagination={true}
        mousewheel={true}
        breakpoints={{
          580: {
            slidesPerView: 2,
          },
        }}
      >
        {imgs.map((Img, index) => (
          <SwiperSlide
            className={classNames("df-center", styles.slide)}
            key={index}
          >
            <img className={styles.img} src={Img} alt="photo PAB" />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};
