import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import styles from "./Form.module.sass";
import { TextField } from "../TextField";
import { setDB } from "../../../../api";
import { useState } from "react";
import { FinishForm } from "./FinishForm";
import { createSearchParams, useNavigate } from "react-router-dom";

enum FieldName {
  Name = "name",
  Company = "company",
  Posada = "posada",
  Phone = "phone",
  Email = "email",
  Text = "text",
}

const labelByName: Record<FieldName, string> = {
  [FieldName.Name]: "ПІБ",
  [FieldName.Company]: "Організація, яку представляєте",
  [FieldName.Posada]: "Посада",
  [FieldName.Phone]: "Номер телефону",
  [FieldName.Email]: "Електронна пошта",
  [FieldName.Text]: "Коротко опишіть свій запит або пропозицію",
};

const schema = yup.object({
  [FieldName.Name]: yup
    .string()
    .trim()
    .label(labelByName[FieldName.Name])
    .min(4)
    .required(),
  [FieldName.Company]: yup
    .string()
    .trim()
    .label(labelByName[FieldName.Name])
    .min(4)
    .required(),
  [FieldName.Posada]: yup
    .string()
    .trim()
    .label(labelByName[FieldName.Name])
    .min(4)
    .required(),
  [FieldName.Phone]: yup
    .string()
    .min(10)
    .max(12)
    .trim()
    .label(labelByName[FieldName.Phone])
    .required(),
  [FieldName.Email]: yup
    .string()
    .trim()
    .email()
    .label(labelByName[FieldName.Email])
    .required(),
  [FieldName.Text]: yup.string().trim().label(labelByName[FieldName.Email]),
});

export type TransactionFiltersFormValues = yup.InferType<typeof schema>;

export const FormPartners = () => {
  const navigate = useNavigate();
  const [close, setClose] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TransactionFiltersFormValues>({
    resolver: yupResolver<TransactionFiltersFormValues>(schema),
    mode: "onBlur",
  });

  const handleCloseFinish = () => {
    navigate({
      search: createSearchParams().toString(),
    });

    setClose(false);
  };

  const handlerSubmit = async (values: any) => {
    const id = await setDB("2023_partners", values);

    if (id) {
      setClose(true);
    }
  };

  return (
    <div className={styles.root}>
      {close ? (
        <FinishForm close={handleCloseFinish} />
      ) : (
        <>
          <h6 className={styles.title}>
            стати партнером PRO СИНТЕЗ бізнес 2.0
          </h6>
          <form onSubmit={handleSubmit(handlerSubmit)}>
            <TextField
              placeholder={labelByName[FieldName.Name]}
              errorMessage={
                errors?.name?.message && "Ім'я має бути мінімум 4 символи"
              }
              label={labelByName[FieldName.Name]}
              register={register(FieldName.Name)}
            />
            <TextField
              placeholder={labelByName[FieldName.Company]}
              errorMessage={
                errors?.company?.message &&
                "Організація, яку представляєте має бути мінімум 4 символи"
              }
              label={labelByName[FieldName.Company]}
              register={register(FieldName.Company)}
            />{" "}
            <TextField
              placeholder={labelByName[FieldName.Posada]}
              errorMessage={
                errors?.posada?.message && "Посада має бути мінімум 4 символи"
              }
              label={labelByName[FieldName.Posada]}
              register={register(FieldName.Posada)}
            />
            <TextField
              placeholder={labelByName[FieldName.Phone]}
              errorMessage={
                errors?.phone?.message &&
                "Номер телефону має бути мінімум 10 символів | максимум 13"
              }
              label={labelByName[FieldName.Phone]}
              register={register(FieldName.Phone)}
            />
            <TextField
              placeholder={labelByName[FieldName.Email]}
              errorMessage={
                errors?.email?.message && "Тут має бути ваш реальний email :)"
              }
              label={labelByName[FieldName.Email]}
              register={register(FieldName.Email)}
            />
            <TextField
              placeholder={labelByName[FieldName.Text]}
              errorMessage={errors?.text?.message}
              label={labelByName[FieldName.Text]}
              register={register(FieldName.Text)}
            />
            <button className={styles.btn}>Відправити заявку</button>
          </form>
        </>
      )}
    </div>
  );
};
