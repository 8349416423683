import { FC, useEffect } from "react";
import { ROUTES } from "../../routing/constants";
import classNames from "classnames/dedupe";
import FacebookIcon from "../../assets/img/facebook.svg";
import TelegramIcon from "../../assets/img/telegram.svg";
import InstagramIcon from "../../assets/img/instWhite.svg";
import TiBoxLogo from "../../assets/img/TiBox.jpg";
import IconClose from "../../assets/img/close.svg";
import styles from "./Menu.module.sass";
import { useBodyClass } from "../../hooks/useLockBody";
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  TELEGRAM_LINK,
  TIBOX_LINK,
} from "../../constants/links";

const LOCK_BODY_CLASS = "lock";

interface IMenuProps {
  onClose: () => void;
}

export const Menu: FC<IMenuProps> = ({ onClose }) => {
  const { lockBody, unlockBody } = useBodyClass({ className: LOCK_BODY_CLASS });

  useEffect(() => {
    lockBody();

    return () => {
      unlockBody();
    };
  }, []);

  return (
    <div className={classNames("df-between fd-column", styles.root)}>
      <button className={styles.btn} onClick={onClose}>
        <img src={IconClose} alt="icon: Close" />
      </button>
      <div className={styles.w100}>
        <p className={styles.title}>Навігація</p>
        <ul className={classNames(styles.list, styles.nav)} onClick={onClose}>
          <li>
            <a href={`${ROUTES.main.createURL()}#forumInfo`}>Про форум</a>
          </li>
          <li>
            <a href={`${ROUTES.main.createURL()}#tickets`}>Квитки</a>
          </li>
          <li>
            <a href={`${ROUTES.main.createURL()}#partners`}>Партнери</a>
          </li>
          <li>
            <a href={`${ROUTES.main.createURL()}#pabInfo`}>Про ПАБ</a>
          </li>
          <li>
            <a href={ROUTES.info.createURL()}>Для ЗМІ</a>
          </li>
        </ul>
      </div>
      <div>
        <ul className={classNames("df-between", styles.list, styles.icons)}>
          <li>
            <a href={FACEBOOK_LINK} target="_blank">
              <img className={styles.icon} src={FacebookIcon} alt="facebook" />
            </a>
          </li>
          <li>
            <a href={TELEGRAM_LINK} target="_blank">
              <img className={styles.icon} src={TelegramIcon} alt="telegram" />
            </a>
          </li>
          <li>
            <a href={INSTAGRAM_LINK} target="_blank">
              <img
                className={styles.icon}
                src={InstagramIcon}
                alt="instagram"
              />
            </a>
          </li>
        </ul>
        <a
          href={TIBOX_LINK}
          target="_blank"
          className={classNames(styles.mainIcon, styles.mainIconMain)}
        >
          <img className={styles.mainIcon} src={TiBoxLogo} alt="icon: TiBox" />
        </a>
      </div>

      <ul className={classNames(styles.list, styles.contacts)}>
        <li>
          <a href="tel:+38 (066) 812 71 53">+38 (066) 812 71 53</a>
        </li>
        <li>
          <a href="mailto:pab.poltava@gmail.com">pab.poltava@gmail.com</a>
        </li>
      </ul>
    </div>
  );
};
