import styles from "./Spikers.module.sass";
import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from "swiper";
import classNames from "classnames/dedupe";
import Spiker1 from "../../../../assets/img/spikers/spiker1.jpg";
import Spiker2 from "../../../../assets/img/spikers/spiker2.jpg";
import Spiker3 from "../../../../assets/img/spikers/spiker3.jpg";
import Spiker4 from "../../../../assets/img/spikers/spiker4.jpg";
import Spiker5 from "../../../../assets/img/spikers/spiker5.jpg";
import Spiker6 from "../../../../assets/img/spikers/spiker6.jpg";
import Spiker7 from "../../../../assets/img/spikers/spiker7.jpg";
import Spiker8 from "../../../../assets/img/spikers/spiker8.jpg";
import Spiker10 from "../../../../assets/img/spikers/ivan_naumenko.jpg";
import Spiker9 from "../../../../assets/img/spikers/spiker9.jpg";
import Spiker0 from "../../../../assets/img/spikers/spiker0.jpg";
import LogoSpiker1 from "../../../../assets/img/spikers/logo_spiker1.png";
import LogoSpiker2 from "../../../../assets/img/spikers/logo_spiker_bussiness.png";
import LogoSpiker3 from "../../../../assets/img/spikers/logo_spiker_3.png";
import LogoSpiker4 from "../../../../assets/img/spikers/logo_spiker_4.png";
import LogoSpiker5 from "../../../../assets/img/spikers/logo_spiker_5.png";
import LogoSpiker6 from "../../../../assets/img/spikers/logo_spiker_6.png";
import LogoSpiker7 from "../../../../assets/img/spikers/logo_spiker_7.png";
import LogoSpiker8 from "../../../../assets/img/spikers/logo_spiker_8.png";
import LogoSpiker9 from "../../../../assets/img/partners/avroraLogo.svg";
import LogoSpiker10 from "../../../../assets/img/zsu.svg";

const spikers = [
  {
    name: "Іван Компан",
    logo: LogoSpiker1,
    photo: Spiker1,
  },
  {
    name: "Тарас Панасенко",
    logo: LogoSpiker9,
    photo: Spiker9,
  },
  {
    name: "Валерій Носенко",
    logo: LogoSpiker2,
    photo: Spiker2,
  },
  {
    name: "Віктор Галасюк",
    logo: LogoSpiker3,
    photo: Spiker3,
    title: "@ViktorHalasiuk",
    link: "https://www.youtube.com/c/ViktorHalasiuk",
  },
  {
    name: "Михайло Пацан",
    photo: Spiker4,
    logo: LogoSpiker4,
  },
  {
    name: "Олександр Савчук",
    logo: LogoSpiker5,
    photo: Spiker5,
  },
  {
    name: "Василь Волошинівський",
    logo: LogoSpiker6,
    photo: Spiker6,
  },
  {
    name: "Сергій Позняк",
    logo: LogoSpiker7,
    photo: Spiker7,
  },
  {
    name: "Олександр Сударкін",
    logo: LogoSpiker8,
    photo: Spiker8,
  },
  {
    name: "Іван Науменко",
    photo: Spiker10,
    logo: LogoSpiker10,
  },
];

export const Spikers: FC = () => {
  return (
    <div className={styles.root}>
      <h6 className={styles.title}>спікери</h6>

      <Swiper
        className={styles.swiper}
        slidesPerView={1}
        modules={[Pagination, Mousewheel]}
        spaceBetween={38}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          580: {
            slidesPerView: 4,
            spaceBetween: 18,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 60,
          },
        }}
      >
        {spikers.map((item, index) => (
          <SwiperSlide
            className={classNames("df-center", styles.slide)}
            key={index}
          >
            <img className={styles.img} src={item.photo} alt="photo PAB" />
            <div className={styles.content}>
              <p className={styles.text}>{item.name}</p>
              {item?.logo && (
                <a
                  className={styles.link}
                  title={item.title || ""}
                  href={item.link}
                  target={"_blank"}
                >
                  <img className={styles.logo} src={item.logo} alt={"logo"} />
                </a>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
