import { Container } from "../../../../components/Container";
import { Button, ButtonType } from "../../../../components/Button";
import classNames from "classnames/dedupe";
import { MAP_OF_SHELTERS } from "../../../../constants/links";
import styles from "./Maps.module.sass";

export const Maps = () => {
  return (
    <div className={styles.root}>
      <Container className={classNames("df-between", styles.container)}>
        <p className={styles.title}>
          Ми дбаємо про вашу безпеку і враховуємо вимоги воєнного стану.
          Ознайомтеся з картою найближчих укриттів.
        </p>
        <Button
          size={ButtonType.BigGreen}
          name={"Переглянути"}
          link={MAP_OF_SHELTERS}
          target="_blank"
        ></Button>
      </Container>
    </div>
  );
};
