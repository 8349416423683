import BannerImg from "../../../../assets/img/Banner2.jpg";
import { Container } from "../../../../components/Container";
import classNames from "classnames/dedupe";
import styles from "./ForumHighlights.module.sass";

const highlightsData = [
  { number: "5", text: "networking spaces", extraText: null },
  { number: "10", text: "спікерів – практиків", extraText: null },
  { number: "75%", text: "учасників - підприємці", extraText: null },
  { number: "250+", text: "учасників", extraText: null },
  { number: "10+", text: "годин обговорення", extraText: "понад" },
];

export const ForumHighlights = () => {
  return (
    <div className={styles.root} id='forumInfo'>
      <div className={styles.bannerWrapper}>
        <img className="img" src={BannerImg} alt="img: Banner" />
      </div>

      <Container className={classNames("df-center", styles.container)}>
        <div className={styles.grid}>
          <div className={styles.item}>
            <p className={styles.title}>
              Що <strong>ви отримаєте</strong> на форумі
            </p>
          </div>

          {highlightsData.map(({ number, text, extraText }) => (
            <div key={text} className={styles.item}>
              {extraText && <p className={classNames(styles.text, styles.extraText)}>{extraText}</p>}
              <strong className={styles.number}>{number}</strong>
              <p className={styles.text}>{text}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};
