import { createContext, FC, ReactNode, useState } from "react";
import { Menu } from "../components/Menu";

interface IMenuProviderProps {
  children: ReactNode;
}

interface IOpenMenuData {
  open: boolean;
}

interface IMenuContextProps {
  showMenu: (data: IOpenMenuData) => void;
}

export const MenuContext = createContext<IMenuContextProps>({
  showMenu: () => {},
});

export const MenuProvider: FC<IMenuProviderProps> = ({ children }) => {
  const [menuState, setMenuState] = useState<IOpenMenuData | null>(null);

  const showMenu = (open: IOpenMenuData) => {
    setMenuState(open);
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <MenuContext.Provider value={{ showMenu }}>
      {children}
      {menuState && <Menu onClose={handleCloseMenu} />}
    </MenuContext.Provider>
  );
};
