import React from "react";
import { Router } from "./routing/Router";
import { MenuProvider } from "./contexts/MenuContext";
import { DialogProvider } from "./contexts/DialogContext";

function App() {
  return (
    <MenuProvider>
      <DialogProvider>
        <Router />
      </DialogProvider>
    </MenuProvider>
  );
}

export default App;
