import BannerImg from "../../../../assets/img/Banner.jpg";
// @ts-ignore
import Video from "../../../../assets/video/videoBg.mp4";
import { Container } from "../../../../components/Container";
import { Logo, LogoSize } from "../../../../components/Logo";
import UkrSibBank from "../../../../assets/img/partners/ukrSibBank.png";
import LogoPab from "../../../../assets/img/logoPabGreen.svg";
import { Button } from "../../../../components/Button";
import classNames from "classnames/dedupe";
import styles from "./Banner.module.sass";
import { useEffect } from "react";

export const Banner = () => {
  useEffect(() => {
    setTimeout(function () {
      // @ts-ignore
      document.querySelector("#video")?.play();
    }, 1000);
  });

  return (
    <div className={styles.root}>
      <div className={styles.bannerWrapper}>
        <video
          id="video"
          className="img"
          poster={BannerImg}
          autoPlay
          loop
          muted
          playsInline={true}
          preload={"none"}
        >
          <source src={Video} />
        </video>
      </div>

      <Container className={classNames("df-center", styles.container)}>
        <div className={styles.logoWrapper}>
          <Logo
            className={classNames(styles.logo, styles.logoWhite)}
            size={LogoSize.big}
          />
          <Logo
            className={classNames(styles.logo, styles.logoColor)}
            size={LogoSize.big}
            color={true}
          />
        </div>
        <h1 className={styles.title}>ПЕРШИЙ ПОЛТАВСЬКИЙ БІЗНЕС-ФОРУМ</h1>
        <div className="df-between">
          <p className={styles.subtitle}>3 ЛИСТОПАДА</p>
          <hr className={styles.line} />
          <p className={styles.subtitle}>ПОЛТАВА</p>
        </div>
        <Button className={classNames(styles.mt3, styles.btn)} />
        <div className={classNames("df-between", styles.info)}>
          <div className={classNames("df-center", styles.infoBlock)}>
            <p>Організатор</p>
            <img src={LogoPab} alt="logo: Pab" />
          </div>
          <div className={classNames("df-center", styles.infoBlock)}>
            <p>Головний партнер</p>
            <img src={UkrSibBank} alt="logo: UKRSIBBANK" />
          </div>
        </div>
      </Container>
    </div>
  );
};
