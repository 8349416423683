const MAIN_ROOT_PATH = "/main";
const INFO_ROOT_PATH = "/info";

export const ROUTES = {
  main: {
    path: MAIN_ROOT_PATH,
    createURL: () => ROUTES.main.path,
  },
  info: {
    path: INFO_ROOT_PATH,
    createURL: () => INFO_ROOT_PATH,
  },
} as const;
