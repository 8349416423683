import { FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "./constants";
import { MainPage } from "../features/main/page";
import { Layout } from "../components/Layout";
import { FormPage } from "../features/formPage/page/TabPage";
import ScrollToHashElement from "../components/ScrollToHashElement";

const DEFAULT_PATH = ROUTES.main.createURL();

export const Router: FC = () => (
  <BrowserRouter>
    <ScrollToHashElement />
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to={DEFAULT_PATH} />} />
        <Route path={ROUTES.main.path} element={<MainPage />} />
        <Route path={ROUTES.info.path} element={<FormPage />} />
        <Route path="*" element={<Navigate to={DEFAULT_PATH} />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
