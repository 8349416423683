import { createContext, FC, ReactNode, useState } from "react";
import { DialogWorkshop } from "../components/DialogWorkshop";

interface IDialogProviderProps {
  children: ReactNode;
}

interface IOpenDialogData {
  open: boolean;
}

interface IDialogContextProps {
  showDialog: (data: IOpenDialogData) => void;
}

export const DialogContext = createContext<IDialogContextProps>({
  showDialog: () => {},
});

export const DialogProvider: FC<IDialogProviderProps> = ({ children }) => {
  const [dialogState, setDialogState] = useState<IOpenDialogData | null>(null);

  const showDialog = (open: IOpenDialogData) => {
    setDialogState(open);
  };

  const handleCloseMenu = () => {
    setDialogState(null);
  };

  return (
    <DialogContext.Provider value={{ showDialog }}>
      {children}
      {dialogState && <DialogWorkshop onClose={handleCloseMenu} />}
    </DialogContext.Provider>
  );
};
