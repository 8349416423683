import { FC } from "react";
import styles from "./LifeTree.module.scss";
import classNames from "classnames/dedupe";
import Img from "../../../../assets/img/lifeTree.jpg";

export const LifeTree: FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <h6 className={styles.title}>
          Перформанс у віртуальній реальності
          <br /> «Дерево Життя»
        </h6>

        <p className={styles.subtitle}>У рамках форуму PRO СИНТЕЗ бізнес 2.0</p>

        <div className={classNames("df-between", styles.content)}>
          <img className={styles.img} src={Img} alt={"img: Life Tree"} />
          <div className={classNames(styles.text)}>
            <p>Що таке пам’ять? Яка вона? Чи можна до неї доторкнутись?</p>
            <p>
              Мій перформанс у віртуальній реальності - це візуалізація подорожі
              у часі та пам’яті, де поєднуються різні частки нашої культурної
              спадщини, зафіксовані у різні часи та різними візуальними
              засобами. Поєднуючи різні артефакти нашої спільної пам’яті,
              дослідивши минуле у теперішньому, ми можемо побудувати шлях у
              майбутнє.Бо саме у єднанні народжуються острови пам’яті, де росте
              Дерево Життя.
            </p>
          </div>
        </div>
        <p className={styles.subText}>
          Тая Кабаєва - трансдисциплінарна мисткиня та креативна продюсерка,
          перша художниця-перформерка у віртуальній реальності в Україні, у свої
          мистецькій практиці поєднує new media технології з темами пам’яті,
          епосу, культурної спадщини,урбаністичних артефактів та публічної
          історії.
        </p>
      </div>
    </div>
  );
};
