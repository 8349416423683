import { Banner } from "../components/Banner";
import { Timer } from "../components/Timer";
import { ForumHighlights } from "../components/ForumHighlights";
import { InfoPanel } from "../components/InfoPanel";
import { ZmiInfo } from "../components/ZMIInfo";
import { Tickets } from "../components/Tickets";
import { Maps } from "../components/Maps";
import { Partners } from "../components/Partners";
import { InfoPab } from "../components/InfoPab";
import { ArrowTop } from "../../../components/ArrowTop";
import { SliderCarusel } from "../components/SliderCarusel";
import { Spikers } from "../components/Spikers";
import { Program } from "../components/Program";
import { BusinessSchool } from "../components/BusinessSchool";
import { LifeTree } from "../components/LifeTree";
import { DialogWorkshop } from "../../../components/DialogWorkshop";

const END_DATA = "2023-11-03T11:00:00.000";

export const MainPage = () => {
  return (
    <>
      <Banner />
      <Timer targetDate={END_DATA} />
      <ForumHighlights />
      <InfoPanel />
      <ZmiInfo />
      <Spikers />
      <Program />
      <Tickets />
      <Timer targetDate={END_DATA} />
      <Partners />
      <Maps />
      <InfoPab />
      <SliderCarusel />
      <BusinessSchool />
      <LifeTree />
      <ArrowTop />
    </>
  );
};
