import { addDoc, collection } from "firebase/firestore";
import { firebaseConfig } from "./firebaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export const setDB = async (tableName, data) => {
  try {
    const docRef = await addDoc(collection(db, tableName), data);

    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    return null;
  }
};
