import { FC } from "react";
import classNames from "classnames/dedupe";
import styles from "./TextField.module.sass";
import { UseFormRegisterReturn } from "react-hook-form";

interface ITextFieldProps {
  className?: string;
  placeholder?: string;
  errorMessage?: string;
  label: string;
  register: UseFormRegisterReturn<string>;
}

export const TextField: FC<ITextFieldProps> = ({
  className,
  placeholder = "",
  errorMessage,
  label,
  register,
}) => {
  return (
    <div className={styles.root}>
      {/*<label className={styles.label}>{label}</label>*/}
      <input
        className={classNames(styles.input, className)}
        placeholder={placeholder}
        {...register}
      />


    </div>
  );
};
