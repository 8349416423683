import styles from "./FormPage.module.sass";
import classNames from "classnames/dedupe";
import { Tabs } from "../../components/Tabs";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormZmi } from "../../components/Form";
import { FormZsu } from "../../components/Form/FormZsu";
import { FormPartners } from "../../components/Form/FormPartners";

export enum FormType {
  zsu = "zsu",
  zmi = "zmi",
  partners = "partners",
}

const staticSearchParams: string = "?form=";

export const FormPage = () => {
  const location = useLocation();
  const [currentForm, setCurrentForm] = useState(""); // Додали стан currentForm

  useEffect(() => {
    const searchParams = location.search.replace(staticSearchParams, "");
    if (
      searchParams === FormType.zsu ||
      searchParams === FormType.zmi ||
      searchParams === FormType.partners
    ) {
      setCurrentForm(searchParams);
    } else {
      setCurrentForm("");
    }
  }, [location]);

  return (
    <>
      <div className={classNames("df-center", styles.root)}>
        {currentForm === FormType.zmi && <FormZmi />}
        {currentForm === FormType.zsu && <FormZsu />}
        {currentForm === FormType.partners && <FormPartners />}
        {!currentForm && <Tabs />}
      </div>
    </>
  );
};
