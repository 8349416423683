import { FC } from "react";
import styles from "./Button.module.sass";
import classNames from "classnames/dedupe";
import { TIBOX_LINK } from "../../constants/links";
import ReactPixel from "react-facebook-pixel";

export enum ButtonType {
  Big = "big",
  Medium = "medium",
  Small = "small",
  BigGreen = "bigGreen",
  BigBlue = "bigBlue",
}

interface IButtonProps {
  size?: ButtonType;
  name?: string;
  link?: string;
  onClick?: () => void;
  className?: string;
  target?: string;
}

export const Button: FC<IButtonProps> = ({
  size = ButtonType.Big,
  name = "Придбати квиток",
  link = TIBOX_LINK,
  onClick,
  className = "",
  ...restProps
}) => {
  const getClassNameBySize: Record<ButtonType, string> = {
    [ButtonType.Big]: styles.big,
    [ButtonType.Medium]: styles.medium,
    [ButtonType.Small]: styles.small,
    [ButtonType.BigGreen]: styles.bigGreen,
    [ButtonType.BigBlue]: styles.bigBlue,
  };

  const classBySize = getClassNameBySize[size];

  ReactPixel.init("995421881575464");

  const handleClick = () => {
    if (link === TIBOX_LINK) {
      ReactPixel.trackCustom("ClickByButton");
    }
  };

  const handleOnClick = () => {
    ReactPixel.trackCustom("ClickByWorkshopButton");

    if (onClick) {
      onClick();
    }
  };

  const getButton = () => (
    <button
      onClick={handleOnClick}
      className={classNames("df-center", styles.button, classBySize, className)}
    >
      {name}
    </button>
  );

  return onClick ? (
    getButton()
  ) : (
    <a
      onClick={handleClick}
      className={classNames("df-center", styles.button, classBySize, className)}
      href={link}
      target={link === TIBOX_LINK ? "_blank" : ""}
      {...restProps}
    >
      {name}
    </a>
  );
};
