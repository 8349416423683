import React from "react";
import { Container } from "../../../../components/Container";
import LogoPab from "../../../../assets/img/PabLogoWhite.svg";
import BorderImg from "../../../../assets/img/border.svg";
import classNames from "classnames/dedupe";
import styles from "./InfoPab.module.sass";

export const InfoPab = () => {
  return (
    <Container className={styles.container} id="pabInfo">
      <p className={styles.title}>про паб</p>
      <p className={styles.subtitle}>
        «Ми є бізнес-об'єдання, що діє
        <br /> назовні для суспільного розвитку»
      </p>
      <div className="df-center">
        <img className={styles.borderImg} src={BorderImg} alt="img: Border" />
      </div>
      <div className={"df-center"}>
        <div className={styles.author}>
          <p className={styles.name}>Ірина Бессарабова</p>
          <p className={styles.subname}>
            керівниця Полтавської асоціації бізнесу
          </p>
        </div>
      </div>
      <div className={styles.info}>
        <p className={styles.infoTitle}>Що ми вже робимо?</p>
        <ul className={classNames("df-between", styles.list)}>
          <li>Заходи клубного формату</li>
          <hr className={styles.line} />
          <li>Публічні проєкти для формування бізнес-середовища</li>
          <hr className={styles.line} />
          <li>Ціннісні проєкти для суспільного розвитку</li>
        </ul>
        <hr className={classNames(styles.line, styles.lineAdd)} />
        <div className={classNames("df-between", styles.banner)}>
          <img
            className={classNames(styles.img)}
            src={LogoPab}
            alt="icon: Logo Pab"
          />
          <p className={styles.text}>
            <strong>Візія ПАБ</strong> – Ми, як бізнес-спільнота, об’єдналися
            заради розвитку власної справи та суспільства, щоб створити
            комфортне середовище навколо себе заради нової України, починаючи з
            Полтави.
          </p>
        </div>
      </div>
    </Container>
  );
};
