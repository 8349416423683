import { Container } from "../../../../components/Container";
import classNames from "classnames/dedupe";
import PabLogo from "../../../../assets/img/PabLogo.svg";
import UkrSibBank from "../../../../assets/img/partners/ukrSibBankBlack.png";
import AvroraLogo from "../../../../assets/img/partners/avroraLogo.svg";
import UkrLogo from "../../../../assets/img/partners/ukrLogo.svg";
import Autotrans from "../../../../assets/img/partners/autotransLogo.svg";
import Yavir from "../../../../assets/img/partners/yavirLogo.svg";
import EPilot from "../../../../assets/img/partners/epilotLogo.svg";
import GreenEl from "../../../../assets/img/partners/greenSlonLogo.svg";
import Konsort from "../../../../assets/img/partners/konsortLogo.svg";
import ImigM from "../../../../assets/img/partners/imigLogo.svg";
import TorgService from "../../../../assets/img/partners/torgservisLogo.svg";
import K from "../../../../assets/img/partners/kLogo.svg";
import Ende from "../../../../assets/img/partners/endevourLogo.svg";
import Digicode from "../../../../assets/img/partners/digicodeLogo.svg";
import PalitraMix from "../../../../assets/img/partners/palitraMix.svg";
import RostTreid from "../../../../assets/img/partners/rostTreid.svg";
import WorldVine from "../../../../assets/img/partners/worldVine.svg";
import R9 from "../../../../assets/img/partners/r9.svg";
import PTV from "../../../../assets/img/partners/ptv.svg";
import Premier from "../../../../assets/img/partners/premier.svg";
import KoloNews from "../../../../assets/img/partners/koloNews.svg";
import FoodGo from "../../../../assets/img/partners/foodGo.svg";
import Boord from "../../../../assets/img/partners/boord.svg";

import styles from "./Partners.module.sass";

export const Partners = () => {
  const partnersList = [
    { img: PabLogo, name: "Організатор" },
    { img: UkrSibBank, name: "Головний партнер" },
    { img: AvroraLogo, name: "Учасник ПАБу" },
    { img: UkrLogo, name: "Учасник ПАБу" },
    { img: Autotrans, name: "Учасник ПАБу" },
    { img: Yavir, name: "Учасник ПАБу" },
    { img: EPilot, name: "Учасник ПАБу" },
    { img: GreenEl, name: "Учасник ПАБу" },
    { img: Konsort, name: "Учасник ПАБу" },
    { img: ImigM, name: "Учасник ПАБу" },
    { img: TorgService, name: "Учасник ПАБу" },
    { img: K, name: "Учасник ПАБу" },
    { img: Ende, name: "Учасник ПАБу" },
    { img: Digicode, name: "Учасник ПАБу" },
    { img: PalitraMix, name: "Учасник ПАБу" },
    { img: RostTreid, name: "Учасник ПАБу" },
  ];

  const partnersSpecial = [
    { img: R9, name: "Креативний партнер" },
    { img: KoloNews, name: "Інформаційний партнер" },
    { img: PTV, name: "Інформаційний партнер" },
    { img: Boord, name: "Інформаційний партнер" },
    { img: FoodGo, name: "Смачний парнер" },
    { img: Premier, name: "Гостинний партнер" },
    { img: WorldVine, name: "Винний партнер" },
  ];

  const getParner = (img: string, name: string, className: string) => (
    <div key={img} className={classNames("df-between fd-column", className)}>
      <div className={classNames("df-center", styles.img)}>
        <img className="img" src={img} alt="img: Partner" />
      </div>
      <p className={styles.text}>{name}</p>
    </div>
  );

  return (
    <Container
      className={classNames("df-between", styles.container)}
      id="partners"
    >
      <p className={styles.title}>ПАРТНЕРИ ФОРУМУ</p>
      <div className={styles.wrapper}>
        {partnersList.map((item) =>
          getParner(item.img, item.name, styles.item),
        )}
      </div>
      <div className={classNames(styles.wrapper, styles.wrapperSmall)}>
        {partnersSpecial.map((item) =>
          getParner(item.img, item.name, styles.item1),
        )}
      </div>
    </Container>
  );
};
