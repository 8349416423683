import { FC } from "react";
import styles from "./DialogWorkshop.module.sass";
import classNames from "classnames/dedupe";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import CloseIcon from "../../assets/img/close_black.svg";

interface IDialogWorkshopProps {
  onClose: () => void;
}

export const DialogWorkshop: FC<IDialogWorkshopProps> = ({ onClose }) => {
  return (
    <div className={classNames("df-center", styles.root)} onClick={onClose}>
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeBtn} onClick={onClose}>
          <img src={CloseIcon} alt={"icon: Close"} />
        </button>
        <h6 className={styles.title}>workshop</h6>
        <Swiper
          className={classNames("swiperWorkshop", styles.swiper)}
          slidesPerView={1}
          direction={"horizontal"}
          spaceBetween={40}
          modules={[Pagination]}
          pagination={true}
          breakpoints={{
            580: {
              spaceBetween: 0,
              direction: "vertical",
              slidesPerView: 2,
            },
          }}
        >
          <SwiperSlide className={classNames("df-center", styles.slide)}>
            <div className={classNames(styles.row, styles.rowFirst)}>
              <div className={"df-center"}>
                <p className={styles.time}>12:00</p>
              </div>
              <div className={styles.description}>
                <h5 className={styles.subtitle}>
                  Діджиталізація компанії: з чого почати і як не треба робити
                  від Digicode
                </h5>
                <p className={styles.text}>
                  Подолання розриву між офлайн та онлайн бізнесом: чому
                  офлайн-бізнесу потрібно і вигідно проводити діджиталізацію
                </p>
              </div>
              <div className={classNames("df-center", styles.price)}>
                <p className={styles.textPrice}>
                  300<sup>грн</sup>
                </p>
                <a
                  href={
                    "https://tickets.tibox.me/e/vorksop-1-pro-sintez-biznes-forum-20"
                  }
                  target={"_blank"}
                  className={classNames("df-center", styles.btn)}
                >
                  Придбати workshop
                </a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.row}>
              <div className={"df-center"}>
                <p className={styles.time}>15:00</p>
              </div>
              <div className={styles.description}>
                <h5 className={styles.subtitle}>Як стати партнером Аврори?</h5>
                <p className={styles.text}>
                  1. Спільні цілі та взаємозв'язок - запорука успішних
                  результатів обох сторін.
                </p>
                <p className={styles.text}>
                  2. Бізнес-партнером може стати кожен, хто відповідає ключовим
                  критеріям.
                </p>
                <p className={styles.text}>
                  3. Три ефективні кроки, як стати бізнес партнером.
                </p>
              </div>
              <div className={classNames("df-center", styles.price)}>
                <p className={styles.textPrice}>
                  300<sup>грн</sup>
                </p>
                <a
                  href={
                    "https://tickets.tibox.me/e/vorksop-2-pro-sintez-biznes-forum-20"
                  }
                  target={"_blank"}
                  className={classNames("df-center", styles.btn)}
                >
                  Придбати workshop
                </a>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
