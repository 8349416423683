import { Container } from "../../../../components/Container";
import { Button, ButtonType } from "../../../../components/Button";
import styles from "./ZMIInfo.module.sass";
import { ROUTES } from "../../../../routing/constants";
import classNames from "classnames/dedupe";

export const ZmiInfo = () => {
  return (
    <div className={styles.root}>
      <Container className={classNames("df-between", styles.container)}>
        <div>
          <p className={styles.title}>
            Інформація для партнерів, ЗМІ та бійців сил оборони України
          </p>
          <p className={styles.text}>
            Вхід для військовослужбовців та ветеранів безоплатний за умови
            попередньої реєстрації
          </p>
        </div>
        <Button
          size={ButtonType.BigBlue}
          name={"Заповнити форму"}
          link={ROUTES.info.createURL()}
        ></Button>
      </Container>
    </div>
  );
};
