import { FC } from "react";
import styles from "./Program.module.sass";
import classNames from "classnames/dedupe";
import Spiker1 from "../../../../assets/img/spikers/spiker1.jpg";
import Spiker10 from "../../../../assets/img/spikers/ivan_naumenko.jpg";
import Spiker2 from "../../../../assets/img/spikers/spiker2.jpg";
import Spiker3 from "../../../../assets/img/spikers/spiker3.jpg";
import Spiker4 from "../../../../assets/img/spikers/spiker4.jpg";
import Spiker5 from "../../../../assets/img/spikers/spiker5.jpg";
import Spiker6 from "../../../../assets/img/spikers/spiker6.jpg";
import Spiker7 from "../../../../assets/img/spikers/spiker7.jpg";
import Spiker8 from "../../../../assets/img/spikers/spiker8.jpg";
import Spiker9 from "../../../../assets/img/spikers/spiker9.jpg";
import LogoSpiker1 from "../../../../assets/img/spikers/logo_spiker1.png";
import LogoSpiker2 from "../../../../assets/img/spikers/logo_spiker_bussiness.png";
import LogoSpiker3 from "../../../../assets/img/spikers/logo_spiker_3.png";
import LogoSpiker4 from "../../../../assets/img/spikers/logo_spiker_4.png";
import LogoSpiker5 from "../../../../assets/img/spikers/logo_spiker_5.png";
import LogoSpiker6 from "../../../../assets/img/spikers/logo_spiker_6.png";
import LogoSpiker10 from "../../../../assets/img/zsu.svg";
import LogosSpiker7 from "../../../../assets/img/spikers/logosSpiker7.png";
import AvroraImg from "../../../../assets/img/partners/avroraLogo.svg";
import LogoSpiker8 from "../../../../assets/img/spikers/logo_spiker_8.png";
import Konsort from "../../../../assets/img/partners/konsortLogo.svg";

const program = [
  {
    name: {
      title: "Блок №1.",
      subtitle: "Особистість. Лідерство & Розвиток бізнесу",
    },
    pointStart: [
      {
        text: "Вступне слово від організаторів та партнерів",
        time: "11:00 - 11:15",
      },
    ],
    program: [
      {
        img: Spiker6,
        topic:
          "Суб’єкт, його кордони, невизначеність і розвиток підприємництва. Що спільного?",
        topicUnknown: false,
        author: "Василь Волошинівський",
        text: "Власник і директор технологічної інженерно-сервісної компанії",
        logoImg: LogoSpiker6,
        classNameLogo: false,
        time: "11:15 - 11:40",
        link: undefined,
      },
      {
        img: Spiker8,
        topic: "Стійкість та резільєнтність бізнесу: уроки війни",
        topicUnknown: false,
        author: "Олександр Сударкін",
        text: "Executive-коуч, бізнес-тренер. Асоційований професор кафедри менеджменту та економіки Бізнес-школи МІМ",
        logoImg: LogoSpiker8,
        classNameLogo: false,
        time: "11:40 - 12:05",
        link: undefined,
      },
      {
        img: Spiker2,
        topic: "Як бізнесу зростати попри зміни: кейси та поради",
        topicUnknown: false,
        author: "Валерій Носенко",
        text: "Керівник відділу регіонального розвитку бізнес клієнтів Київстар",
        logoImg: LogoSpiker2,
        classNameLogo: false,
        time: "12:05 - 12:30",
        link: undefined,
      },
      {
        img: Spiker3,
        topic:
          "Ставати архітекторами власного майбутнього. Як поєднати теорію та українські реалії. online",
        topicUnknown: false,
        author: "Віктор Галасюк",
        text: "Український економіст, президент Української асоціації Римського клубу.",
        logoImg: LogoSpiker3,
        classNameLogo: false,
        time: "12:30 - 12:55",
        link: "https://www.youtube.com/c/ViktorHalasiuk",
      },
      {
        img: Spiker9,
        topic:
          "Аврора: розвиток та масштабування попри війну. як потрапити на полиці найбільшого непродовольчого рітейлу України?",
        topicUnknown: false,
        author: "Тарас ПАНАСЕНКО",
        text: 'Співзасновник мережі магазинів "Аврора Мультимаркет"',
        logoImg: AvroraImg,
        classNameLogo: false,
        time: "12:55 - 13:20",
        link: undefined,
      },
      {
        img: Spiker4,
        topic: "Технології штучного інтелекту в компаніях майбутнього",
        topicUnknown: false,
        author: "Михайло Пацан",
        logoImg: LogoSpiker4,
        text: 'Засновник Web3-університету професій майбутнього "Learn to Earn Global" і блокчейн-протоколу "Amsets"',
        classNameLogo: false,
        time: "13:20 - 13:45",
        link: undefined,
      },
    ],
    pointEnd: [
      {
        text: "Нетворкінг та кава-брейк",
        subtext:
          "* Під час кава-брейку серед власників квитків буде проводитись розіграш подарунків від наших партнерів",
        subTextGreen: true,
        time: "",
        textBold: false,
      },
    ],
  },
  {
    name: {
      title: "Блок №2.",
      subtitle: "Підприємництво і країна. Виклики. Зв'язки. Впливи.",
    },
    program: [
      {
        img: Spiker1,
        topic:
          "Зростання економіки чи криза: на що сподіватися Україні та світу",
        topicUnknown: false,
        author: "Іван Компан",
        text: "Викладач Единбурзької бізнес-школи",
        logoImg: LogoSpiker1,
        classNameLogo: false,
        time: "15:40 - 16:10",
        link: undefined,
      },
      {
        img: Spiker7,
        topic: "Кров ветеранського бізнесу",
        topicUnknown: false,
        author: "Сергій Позняк",
        text: "Підприємець, ветеран",
        logoImg: LogosSpiker7,
        classNameLogo: true,
        time: "16:10 - 16:35",
        link: undefined,
      },
      {
        img: Spiker10,
        topic: "Майбутнє України впродовж та після війни: до чого готуватися",
        topicUnknown: false,
        author: "Іван Науменко",
        text: "Директор Департаменту стратегічних ініціатив фонду «Повернись живим»",
        classNameLogo: false,
        logoImg: LogoSpiker10,
        time: "16:35 - 17:00",
        link: undefined,
      },
      {
        img: Spiker5,
        topic:
          "Чому вдається складне. Будівля Полтавського земства як символ та ідея.",
        topicUnknown: false,
        author: "Олесандр Савчук",
        text: "Підприємець видавець, кандидат філософських наук",
        logoImg: LogoSpiker5,
        classNameLogo: false,
        time: "17:00 - 17:20",
        link: undefined,
      },
    ],
    pointEnd: [
      {
        text: "Мистецький перформанс у віртуальній реальності “Дерево Життя”",
        subtext: "Тая КАБАЄВА",
        textBold: true,
        time: "17:20 - 18:00",
        subTextGreen: false,
      },
    ],
  },
];

export const Program: FC = () => {
  return (
    <div className={styles.root}>
      <h6 className={styles.title}>програма</h6>
      <div className={styles.wrapper}>
        {program.map((block) => (
          <div className={styles.box} key={block.name.title}>
            {block.name.title === "Блок №1." ? (
              <div className={styles.block}>
                <div className={classNames(styles.row, styles.alCenter)}>
                  <div className={styles.time}>
                    <p>10:00 - 11:00</p>
                  </div>

                  <div className={styles.firstChild}>
                    <span className={styles.point}></span>
                  </div>
                  <p className={styles.pointText}>
                    Реєстрація учасників. Вітальна кава
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className={styles.blockName}>
              <p>{block.name.title}</p>
              <strong>{block.name.subtitle}</strong>
            </div>
            {block.name.title === "Блок №2." ? (
              <div className={styles.block}>
                <div className={classNames(styles.row)}>
                  <div className={styles.time}>
                    <p>14:45 - 15:40</p>
                  </div>

                  <div className={styles.firstChild}>
                    <span className={styles.point}></span>
                  </div>
                  <div className={styles.panel}>
                    <h6 className={classNames(styles.topic)}>
                      Панельна дискусія «що очікує на українських підприємців —
                      які сценарії враховувати»
                    </h6>

                    <div className={styles.panel1}>
                      <p className={styles.w}>
                        <strong>Модератор</strong> – Віталій Дмитрієв власник
                        компанії «Konsort»
                      </p>
                      <img src={Konsort} alt={"Konsort logo"} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={styles.block}>
              {block?.pointStart &&
                block.pointStart.map((item) => (
                  <div
                    className={classNames(styles.row, styles.alCenter)}
                    key={item.text}
                  >
                    <div className={styles.time}>
                      <p>{item.time}</p>
                    </div>
                    <div className={styles.firstChild}>
                      <span className={styles.point}></span>
                    </div>
                    <p className={styles.pointText}>{item.text}</p>
                  </div>
                ))}
              {block.program.map((item) => (
                <div
                  className={classNames(styles.row, styles.pb1)}
                  key={item.topic + item.author}
                >
                  <div className={styles.time}>
                    <p>{item.time}</p>
                  </div>
                  <div className={styles.firstChild}>
                    <img
                      className={classNames("img", styles.img)}
                      src={item.img}
                      alt={"spiker's img"}
                    />
                  </div>
                  <div className={styles.program}>
                    <h6
                      className={classNames(
                        styles.topic,
                        item.topicUnknown ? styles.topicGreen : "",
                      )}
                    >
                      {item.topic}
                    </h6>
                    <p className={styles.author}>{item.author}</p>
                    <div className={classNames("df-between", styles.info)}>
                      <p className={styles.infoText}>{item.text}</p>
                      {item.logoImg && (
                        <a href={item?.link} target={"_blank"}>
                          <img
                            className={classNames(
                              styles.logoImg,
                              item?.classNameLogo ? styles.logoImgLong : "",
                            )}
                            src={item.logoImg}
                            alt={"spiker's logo"}
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {block?.pointEnd &&
                block.pointEnd.map((item) => (
                  <div className={classNames(styles.row)} key={item.text}>
                    <div className={styles.time}>
                      <p>{item.time}</p>
                    </div>
                    <div className={styles.firstChild}>
                      <span className={styles.point}></span>
                    </div>
                    <div className={styles.pointEnd}>
                      <p
                        className={classNames(
                          styles.pointText,
                          item.textBold ? styles.pointTextBold : "",
                        )}
                      >
                        {item.text}
                      </p>
                      {item?.subtext ? (
                        <p
                          className={classNames(
                            styles.author,
                            item.subTextGreen && styles.green,
                          )}
                        >
                          {item.subtext}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
