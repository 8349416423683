import { FC } from "react";
import styles from "./Tabs.module.sass";
import classNames from "classnames/dedupe";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FormType } from "../../page/TabPage";

export const Tabs: FC = () => {
  const navigate = useNavigate();

  const handlePushHistory = (searchParams: string) => {
    navigate({
      search: createSearchParams({ form: searchParams }).toString(),
    });
  };

  return (
    <div className={styles.root}>
      <h4 className={styles.title}>
        Інформація для ЗМІ, партнерів та бійців сил оборони україни
      </h4>
      <div className={classNames("df-between", styles.wrapper)}>
        <button
          className={styles.button}
          onClick={() => handlePushHistory(FormType.partners)}
        >
          партнери
        </button>
        <button
          className={styles.button}
          onClick={() => handlePushHistory(FormType.zmi)}
        >
          ЗМІ
        </button>
        <button
          className={styles.button}
          onClick={() => handlePushHistory(FormType.zsu)}
        >
          сили оборони україни
        </button>
      </div>
    </div>
  );
};
